import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';

const PopulationPyramid = ({ 
  data, 
  legendLabel = '', 
  xAxisLabel = '',
  barColor = '#6295FF',
  currency = '',  // Empty string for no currency, or 'EUR', 'USD', etc.
  width, 
  height, 
  margin = { top: 40, right: 40, bottom: 40, left: 60 } 
}) => {
  const containerRef = useRef();
  const svgRef = useRef();
  const tooltipRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [observer, setObserver] = useState(null);

  // Create a formatter based on currency
  const formatValue = useCallback((value) => {
    if (!currency) {
      return d3.format(',.0f')(value);
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }, [currency]);

  // Initialize tooltip
  useEffect(() => {
    if (!containerRef.current) return;

    const tooltip = d3.select(containerRef.current)
      .append('div')
      .attr('class', 'population-pyramid-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'rgba(0, 0, 0, 0.8)')
      .style('color', 'white')
      .style('padding', '8px 12px')
      .style('border-radius', '4px')
      .style('font-size', '12px')
      .style('pointer-events', 'none')
      .style('z-index', '9999')
      .style('max-width', '200px')
      .style('box-shadow', '0 2px 4px rgba(0,0,0,0.2)')
      .style('white-space', 'nowrap')
      .style('opacity', 0)
      .style('transition', 'opacity 0.2s ease');

    tooltipRef.current = tooltip;

    return () => {
      tooltip.remove();
    };
  }, []);

  // Memoize the chart rendering function
  const renderChart = useCallback(() => {
    if (!svgRef.current || dimensions.width === 0) return;

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll('*').remove();
    
    // Generate a unique ID for this chart instance
    const chartId = `population-pyramid-${Math.random().toString(36).substr(2, 9)}`;
    
    const svg = d3.select(svgRef.current)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('data-chart-id', chartId); // Add the chart ID as a data attribute

    const innerWidth = dimensions.width - margin.left - margin.right;
    const innerHeight = dimensions.height - margin.top - margin.bottom;

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Find the maximum value and round up to a nice number
    const maxValue = d3.max(data, d => Math.abs(d.value));
    
    // Calculate a nice rounded max value using smaller increments
    const getTickIncrement = (value) => {
      const log10 = Math.floor(Math.log10(value));
      const power = Math.pow(10, log10);
      const normalized = value / power;
      
      if (normalized <= 1.2) return power * 1.2;
      if (normalized <= 1.5) return power * 1.5;
      if (normalized <= 2) return power * 2;
      if (normalized <= 2.5) return power * 2.5;
      if (normalized <= 3) return power * 3;
      if (normalized <= 4) return power * 4;
      if (normalized <= 5) return power * 5;
      if (normalized <= 6) return power * 6;
      if (normalized <= 8) return power * 8;
      return power * 10;
    };

    const roundedMaxValue = getTickIncrement(maxValue);

    // Create scales
    const xScale = d3.scaleLinear()
      .domain([-roundedMaxValue, roundedMaxValue])
      .range([0, innerWidth]);

    const yScale = d3.scaleBand()
      .domain(data.map(d => d.layer))
      .range([0, innerHeight])
      .padding(0.2);

    // Add vertical grid lines first (in the background)
    g.append('g')
      .attr('class', 'grid-lines')
      .selectAll('line')
      .data(xScale.ticks(10))
      .enter()
      .append('line')
      .attr('x1', d => xScale(d))
      .attr('x2', d => xScale(d))
      .attr('y1', 0)
      .attr('y2', innerHeight)
      .attr('stroke', '#e0e0e0')
      .attr('stroke-opacity', 0.3);

    // Function to handle hover effects
    const handleMouseOver = function(event, d) {
      // Highlight the hovered bar pair - scoped to this chart instance only
      d3.select(svgRef.current)
        .selectAll('.bar-path')
        .style('opacity', function() {
          const pathData = d3.select(this).datum();
          return pathData.layer === d.layer ? 1 : 0.3;
        });

      // Show and position tooltip
      const tooltip = tooltipRef.current;
      
      // Get mouse position relative to the page
      const mouseX = event.pageX;
      const mouseY = event.pageY;
      
      // Update tooltip content
      tooltip
        .html(`
          <div style="font-weight: bold;">Layer ${d.layer}</div>
          <div>${legendLabel}: ${formatValue(Math.abs(d.value))}</div>
        `)
        .style('left', `${mouseX + 10}px`)
        .style('top', `${mouseY - 40}px`)
        .style('visibility', 'visible')
        .style('opacity', 1);
    };

    const handleMouseOut = function() {
      // Reset all bars opacity - scoped to this chart instance only
      d3.select(svgRef.current)
        .selectAll('.bar-path')
        .style('opacity', 1);

      // Hide tooltip
      tooltipRef.current
        .style('visibility', 'hidden')
        .style('opacity', 0);
    };

    // Append left-side rectangles
    const bars = g.selectAll('.bar-group')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'bar-group')
      .attr('transform', d => `translate(0,${yScale(d.layer)})`)
      .attr('pointer-events', 'all');

    bars.append('path')
      .attr('class', 'bar-path')
      .attr('d', d => {
        const x = xScale(-d.value);
        const width = xScale(0) - xScale(-d.value);
        const height = yScale.bandwidth();
        return `
          M ${x + 5},${0}
          h ${width - 5}
          v ${height - 5}
          q 0,5 -5,5
          h ${-(width - 5)}
          q -5,0 -5,-5
          v ${-(height - 10)}
          q 0,-5 5,-5
          z
        `;
      })
      .attr('fill', barColor)
      .style('transition', 'opacity 0.2s ease')
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseOver)
      .on('mouseout', handleMouseOut);

    // Append right-side rectangles
    bars.append('path')
      .attr('class', 'bar-path')
      .attr('d', d => {
        const x = xScale(0);
        const width = xScale(d.value) - xScale(0);
        const height = yScale.bandwidth();
        return `
          M ${x},${0}
          h ${width - 5}
          q 5,0 5,5
          v ${height - 10}
          q 0,5 -5,5
          h ${-width}
          v ${-height}
          z
        `;
      })
      .attr('fill', barColor)
      .style('transition', 'opacity 0.2s ease')
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseOver)
      .on('mouseout', handleMouseOut);

    // Add centered value labels with currency
    bars.append('text')
      .attr('x', xScale(0))
      .attr('y', yScale.bandwidth() / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .style('fill', '#333333')
      .style('font-size', '12px')
      .style('font-weight', '500')
      .text(d => formatValue(d.value));

    // Add x-axis with custom styling
    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale)
        .tickSize(-5)
        .ticks(10)
        .tickFormat(d => formatValue(Math.abs(d))))  // Use absolute values for tick labels
      .call(g => g.selectAll('.tick text')
        .style('font-size', '11px')
        .style('fill', '#666666'));

    // Add y-axis with custom styling
    g.append('g')
      .call(d3.axisLeft(yScale))
      .call(g => {
        const path = g.select('.domain').attr('d');
        const newPath = path.split('V')[0] + 'V' + innerHeight;
        g.select('.domain').attr('d', newPath);
      })
      .call(g => g.selectAll('.tick text')
        .style('font-size', '12px')
        .style('fill', '#333333')
        .style('font-weight', '500'));

    // Add x-axis label
    g.append('text')
      .attr('x', innerWidth / 2)
      .attr('y', innerHeight + margin.bottom - 5)
      .style('text-anchor', 'middle')
      .style('fill', '#666666')
      .style('font-size', '12px')
      .text(xAxisLabel);

    // Add legend with improved styling
    const legend = svg.append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top / 2})`);

    legend.append('rect')
      .attr('width', 16)
      .attr('height', 16)
      .attr('fill', barColor)
      .attr('rx', 5)
      .attr('ry', 5);

    legend.append('text')
      .attr('x', 24)
      .attr('y', 12)
      .style('font-size', '12px')
      .style('fill', '#333333')
      .style('font-weight', '500')
      .text(legendLabel);

    // Cleanup function to remove tooltip when component unmounts
    return () => {
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
    };

  }, [data, legendLabel, barColor, margin, dimensions, formatValue]);

  // Handle dimension updates
  useEffect(() => {
    if (!containerRef.current) return;

    const updateDimensions = () => {
      if (!containerRef.current) return;
      
      const { width: containerWidth, height: containerHeight } = containerRef.current.getBoundingClientRect();
      const newWidth = width || containerWidth || 0;
      const newHeight = height || containerHeight || 400;
      
      // Only update if dimensions actually changed
      setDimensions(prev => {
        if (prev.width === newWidth && prev.height === newHeight) {
          return prev;
        }
        return { width: newWidth, height: newHeight };
      });
    };

    // Initial dimensions update
    updateDimensions();

    // Set up ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries || !entries[0]) return;
      window.requestAnimationFrame(() => {
        updateDimensions();
      });
    });

    resizeObserver.observe(containerRef.current);
    setObserver(resizeObserver);

    return () => {
      resizeObserver.disconnect();
    };
  }, [width, height]);

  // Render chart when dimensions change
  useEffect(() => {
    const cleanup = renderChart();
    return () => {
      if (cleanup) cleanup();
    };
  }, [renderChart]);

  const containerStyle = {
    width: width || '100%',
    height: height || '400px',
    position: 'relative'
  };

  useEffect(() => {
    if (!tooltipRef.current) return;
    const cleanup = renderChart();
    return () => cleanup && cleanup();
  }, [renderChart, tooltipRef.current]);

  return (
    <div ref={containerRef} style={containerStyle}>
      <svg ref={svgRef} style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 0 }} />
    </div>
  );
};

export default PopulationPyramid;